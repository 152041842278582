<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-col :span="16">
						<a-row>
							<a-form-item label="戏剧名称" name="name" class="ui-form__item">
								<a-input v-model:value="formState.name" placeholder="请输入戏剧名称"></a-input>
							</a-form-item>
						</a-row>
					</a-col>
					<a-col :span="8" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div>
				<a-tabs type="card" v-model:activeKey="type" @change="onSearch">
					<a-tab-pane :key="1" tab="电影"></a-tab-pane>
					<a-tab-pane :key="2" tab="演出"></a-tab-pane>
				</a-tabs>
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 950 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'action'">
							<a-button type="link" @click="onDetail(record)">查看详情</a-button>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>

	</div>
	<temp v-if="showModal" @back="onBack"></temp>
</template>

<script>
	import temp from './list.vue'
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getCommentTotalList } from '@/service/modules/comment.js';
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				showAll: false,
				searchData: {},
				formState: {},
				type: 1,
				columns: [{
					title: '名称',
					dataIndex: 'businessName',
					width: 100
				}, {
					title: '总评价/条',
					dataIndex: 'commentNum',
					width: 100
				}, {
					title: '新评论/条',
					dataIndex: 'newCommentNum',
					width: 100
				}, {
					title: '精选/条',
					dataIndex: 'isChoiceNum',
					width: 100
				}, {
					title: '底部评价/条',
					dataIndex: 'isBottomNum',
					width: 100
				}, {
					title: '轮播评价/条',
					dataIndex: 'bannerNum',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 100,
					align: 'center'
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await getCommentTotalList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					type: this.type,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount;
				}
			},
			onDetail(item) {
				this.$router.push({
					path: "/comment/film",
					query: {
						id: item.businessId,
						type: item.businessType,
						name: item.businessName
					}
				})
				// this.showModal = true;
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>